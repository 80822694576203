<template>
  <div
    class="popup_wrap"
    style="width:650px;"
  >
    <button
      class="layer_close"
      @click="$emit('closeAttach')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONIM099P070.001') }}</h1><!-- 첨부파일 확인 -->
      <div class="content_box">
        <h2 class="content_title">{{ $t('msg.ONIM099P070.002') }}</h2><!-- Payment 첨부 파일 -->
        <DEXT5Upload
          ref="dextupload"
          id="dextupload1"
          width="100%"
          height="200px"
          :file-info="uploadFileList"
        />
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="insertFile()"
        >{{ $t('msg.ONIM099P070.003') }}</a><!-- Save -->
      </div>
    </div>
  </div>
</template>

<script>

import payment from '@/api/rest/trans/payment'
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'PaymentAttachPop',
  components: { DEXT5Upload },
  props: {
    payNo: {
      type: String,
      default: ''
    },
    parentUploadFileList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      params: {
        payNo: '',
        // 첨부파일 리스트(실제 입력)
        uploadFileInfos: []
      },

      // Dext Uploader와 연결되어 있는 배열
      uploadFileList: []
    }
  },
  created () {
    this._ = require('lodash')
    this.params.payNo = this.payNo
    // this.uploadFileList = this.parentUploadFileList
    this.uploadFileList = this._.cloneDeep(this.parentUploadFileList)
    // this.initPopup()
  },
  methods: {
    // initPopup () {
    //   this.getPaymentAttachList()
    // },
    // 파일 조회
    // getPaymentAttachList () {
    //   payment.popInputPayUpload(this.params.payNo).then(response => {
    //     if (response.headers.respcode === 'C0000') {
    //       let fileList = response.data.filePayList

    //       if (fileList !== undefined) {
    //         fileList.forEach(file => {
    //           this.uploadFileList.push(
    //             {
    //               guid: file.reqRno + '_' + file.fileSeq,
    //               originalName: file.fileNm,
    //               size: file.fileSz,
    //               fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + this.params.payNo + '&atchFileSeq=' + file.fileSeq + '&type=PAYMENT_ATTACH',

    //               reqRno: file.reqRno,
    //               atchFileSeq: file.fileSeq
    //             }
    //           )
    //         })
    //       }
    //     }
    //   })
    // },
    // 저장 or 수정
    async insertFile () {
      // 저장하시겠습니까
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM099P070.004'), useConfirmBtn: true })) {
        this.onConfirmInsert()
      }
    },
    // 저장 Confirm '확인' 클릭 시 콜백 메소드
    async onConfirmInsert () {
      // 실제 입력할 파일리스트를 담는다.
      this.params.uploadFileInfos = this.uploadFileList

      // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
      this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

      // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
      // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
      this.params.uploadFileInfos.forEach(element => {
        if (element.newFlag === undefined || element.newFlag === '') {
          element.fileId = ''
        }
      })

      const TH = this

      // 파일 저장
      await payment.popPayUpload(this.params).then(async response => {
        const result = response.data
        if (response.headers.respcode === 'C0000' && result !== undefined && result.resultCode === 'SUCC') {
          // 저장되었습니다.
          if (await TH.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONIM099P070.005') })) {
            const vo = {
              size: TH.uploadFileList.length
              // modiFlag: TH.uploadFileList.length === TH.parentUploadFileList.length
            }
            TH.$emit('closeAttach', vo)
          }
        } else {
          // 저장에 실패하였습니다. 관리자에게 문의해주시기 바랍니다.
          TH.$ekmtcCommon.alertDefault(TH.$t('msg.ONIM099P070.006'))
        }
      })
    }
  }
}
</script>
